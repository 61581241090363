/**
 * @generated SignedSource<<3f53ca1b957fca4496fc7d87e1a3f74a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MedicaidLevel = "FULL" | "FULL_BENEFIT_DUAL_ELIGIBLE" | "PARTIAL" | "QUALIFIED_DISABLED_WORKING_INDIVIDUAL" | "QUALIFIED_MEDICARE_BENEFICIARY" | "QUALIFIED_MEDICARE_BENEFICIARY_PLUS" | "QUALIFYING_INDIVIDUAL" | "SPECIFIED_LOW_INCOME_MEDICARE_BENEFICIARY" | "SPECIFIED_LOW_INCOME_MEDICARE_BENEFICIARY_PLUS" | "UNKNOWN" | "%future added value";
export type PartDLowIncomeSubsidyLevel = "LEVEL_FOUR_100_PERCENT" | "LEVEL_FOUR_25_PERCENT" | "LEVEL_FOUR_50_PERCENT" | "LEVEL_FOUR_75_PERCENT" | "LEVEL_ONE" | "LEVEL_THREE" | "LEVEL_TWO" | "%future added value";
export type Sex = "FEMALE" | "MALE" | "%future added value";
export type UpsertMedicareProfileInput = {
  estimatedPartBEffectiveDate?: string | null;
  medicaidLevel?: MedicaidLevel | null;
  medicaidNumber?: any | null;
  medicareBeneficiaryId?: string | null;
  partAEffectiveDate?: string | null;
  partBEffectiveDate?: string | null;
  partDLowIncomeSubsidyLevel?: PartDLowIncomeSubsidyLevel | null;
  personId: string;
  residentialAddress?: AddressInput | null;
  usesTobacco?: boolean | null;
};
export type AddressInput = {
  city?: string | null;
  fipsCountyCode?: string | null;
  state?: string | null;
  streetLineOne?: string | null;
  streetLineTwo?: string | null;
  zip?: string | null;
};
export type UpdatePersonInput = {
  advisorId?: string | null;
  birthDate?: string | null;
  legalFirstName?: string | null;
  legalLastName?: string | null;
  mailingAddress?: AddressInput | null;
  personId: string;
  preferredFirstName?: string | null;
  sex?: Sex | null;
  ssn?: any | null;
  suffix?: string | null;
};
export type medicareInfoFormMutation$variables = {
  input: UpsertMedicareProfileInput;
  updatePersonInput: UpdatePersonInput;
};
export type medicareInfoFormMutation$data = {
  readonly updatePerson: {
    readonly person: {
      readonly legalFirstName: string | null;
      readonly legalLastName: string | null;
    };
  } | null;
  readonly upsertMedicareProfile: {
    readonly person: {
      readonly id: string;
      readonly medicareProfile: {
        readonly medicareBeneficiaryId: string | null;
        readonly partAEffectiveDate: string | null;
        readonly partBEffectiveDate: string | null;
      } | null;
    };
  } | null;
};
export type medicareInfoFormMutation = {
  response: medicareInfoFormMutation$data;
  variables: medicareInfoFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "updatePersonInput"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "input",
      "variableName": "input"
    }
  ],
  "concreteType": "UpsertMedicareProfilePayload",
  "kind": "LinkedField",
  "name": "upsertMedicareProfile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "MedicareProfile",
          "kind": "LinkedField",
          "name": "medicareProfile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "medicareBeneficiaryId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "partAEffectiveDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "partBEffectiveDate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "updatePersonInput"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "legalFirstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "legalLastName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "medicareInfoFormMutation",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "UpdatePersonPayload",
        "kind": "LinkedField",
        "name": "updatePerson",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "medicareInfoFormMutation",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "UpdatePersonPayload",
        "kind": "LinkedField",
        "name": "updatePerson",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b7abc1a3a6607f7ff777da3d5bb10dad",
    "id": null,
    "metadata": {},
    "name": "medicareInfoFormMutation",
    "operationKind": "mutation",
    "text": "mutation medicareInfoFormMutation(\n  $input: UpsertMedicareProfileInput!\n  $updatePersonInput: UpdatePersonInput!\n) {\n  upsertMedicareProfile(input: $input) {\n    person {\n      id\n      medicareProfile {\n        medicareBeneficiaryId\n        partAEffectiveDate\n        partBEffectiveDate\n      }\n    }\n  }\n  updatePerson(input: $updatePersonInput) {\n    person {\n      legalFirstName\n      legalLastName\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0c48502180f768592a4b5e1f89cd8f80";

export default node;
