/**
 * @generated SignedSource<<1a0081d37d1519a11e0f7118a1c9901d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RegisterConsumerAppUserInput = {
  consentedToSmsMarketing: boolean;
  phoneNumber?: any | null;
  referralCode?: string | null;
};
export type RegisterUserMutation$variables = {
  input: RegisterConsumerAppUserInput;
};
export type RegisterUserMutation$data = {
  readonly registerConsumerAppUser: {
    readonly account: {
      readonly id: string;
      readonly person: {
        readonly id: string;
      } | null;
    };
  };
};
export type RegisterUserMutation = {
  response: RegisterUserMutation$data;
  variables: RegisterUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RegisterConsumerAppUserPayload",
    "kind": "LinkedField",
    "name": "registerConsumerAppUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChapterAccount",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RegisterUserMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RegisterUserMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "2cb3711c0496927f476d8cdc62d8f2b3",
    "id": null,
    "metadata": {},
    "name": "RegisterUserMutation",
    "operationKind": "mutation",
    "text": "mutation RegisterUserMutation(\n  $input: RegisterConsumerAppUserInput!\n) {\n  registerConsumerAppUser(input: $input) {\n    account {\n      id\n      person {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "191fda1b667ba678e56c7ce2803887cc";

export default node;
