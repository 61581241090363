export type CustomerReviewContent = {
    name: string;
    quote: string;
    year: string;
};

export const RobertReview: CustomerReviewContent = {
    name: "Robert",
    quote: "Let's face it, free is good. Anything helps these days with the high prices. I got all my vitamins and paid nothing for them.",
    year: "2024",
};

export const CarmenReview: CustomerReviewContent = {
    name: "Carmen",
    quote: "In the past, I have let my OTC benefits go because it's just a royal pain. With Chapter, I will definitely be keeping abreast of when I have another $50 benefit to use.",
    year: "2024",
};

export const AliciaReview: CustomerReviewContent = {
    name: "Alicia",
    quote: "Besides the money that is saved, it's nice to be able to do it from home and not have to go out to the store to shop.",
    year: "2024",
};
